<template>
  <tr v-if="order">
    <td class="order-id">{{ order.order_number }}</td>
    <td class="hidden lg:table-cell">
      {{
        moment(order.trade_gecko_created_at ?? order.created_at).format(
          "MMM Do YYYY"
        )
      }}
    </td>
    <td class="hidden 2xl:table-cell">
      <span class="status" :class="order.status.toLowerCase()">{{
        order.status
      }}</span>
    </td>
    <td class="order-amount hidden lg:table-cell">
      ${{ commaFormat(Number(order.OrderTotalPrice).toFixed(2)) }}
    </td>
    <td class="capitalize hidden 2xl:table-cell">{{ order.payment_status }}</td>

    <td class="hidden lg:table-cell">
      <div
        v-if="
          order.status != 'void' &&
            order.invoices != '' &&
            order.invoices.length == 1
        "
      >
        <router-link
          v-for="Inv in order.invoices"
          :key="Inv.id"
          class="inline-flex items-center font-light text-xs border-b border-dashed border-gray-300 cursor-pointer"
          target="_blank"
          :to="'/invoice/' + Inv.id"
        >
          <DocumentIcon class="w-3 h-3 mr-1" />
          <span>Download</span></router-link
        >
      </div>
      <div
        v-if="
          order.status != 'void' &&
            order.invoices != '' &&
            order.invoices.length > 1
        "
        class="text-right"
      >
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="inline-flex items-center font-light text-xs border-b border-dashed border-gray-300 cursor-pointer"
              ><Icon name="document-file" class="w-3 h-3 mr-1" />
              Download
              <DotsVerticalIcon class="ml-1 -mr-1 h-3 w-3" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div
                class="px-1 py-1"
                v-for="(Inv, index) in order.invoices"
                :key="Inv.id"
              >
                <router-link target="_blank" :to="'/invoice/' + Inv.id">
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-violet-500 text-black' : 'text-gray-500',
                        'group flex w-full items-center rounded-md px-2 py-1.5 text-xs',
                      ]"
                    >
                      Invoice {{ index + 1 }}
                    </button>
                  </MenuItem>
                </router-link>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </td>
    <td>
      <router-link
        :to="'/order-details/' + order.id"
        class="flex items-center uppercase font-light text-xs cursor-pointer"
        >Details <ChevronRightIcon class="w-4 h-4 ml-1"
      /></router-link>
    </td>
  </tr>
  <tr class="mobile-row">
    <td colspan="6">
      <ul class="flex flex-col space-y-1">
        <li
          class="flex items-center justify-between bg-gray-50  rounded px-4 py-2"
        >
          <span>Order Date</span>
          <span>{{
            moment(order.trade_gecko_created_at ?? order.created_at).format(
              "MMM Do YYYY"
            )
          }}</span>
        </li>
        <li class="flex items-center justify-between rounded px-4 py-2">
          <span>Amount</span>
          <span class="font-medium text-black"
            >${{ commaFormat(Number(order.OrderTotalPrice).toFixed(2)) }}</span
          >
        </li>
        <li
          class="flex items-center justify-between bg-gray-50  rounded px-4 py-2"
        >
          <span>Status</span>
          <span class="status" :class="order.status.toLowerCase()">{{
            order.status
          }}</span>
        </li>
        <li class="flex items-center justify-between rounded px-4 py-2">
          <span>Invoice</span>
          <div
            v-if="
              order.status != 'void' &&
                order.invoices != '' &&
                order.invoices.length == 1
            "
          >
            <router-link
              v-for="Inv in order.invoices"
              :key="Inv.id"
              class="inline-flex items-center font-light text-xs border-b border-dashed border-gray-300 cursor-pointer"
              target="_blank"
              :to="'/invoice/' + Inv.id"
            >
              <DocumentIcon class="w-3 h-3 mr-1" />
              <span>Download</span></router-link
            >
          </div>
          <div
            v-if="
              order.status != 'void' &&
                order.invoices != '' &&
                order.invoices.length > 1
            "
            class="text-right"
          >
            <Menu as="div" class="relative inline-block text-left">
              <div>
                <MenuButton
                  class="inline-flex items-center font-light text-xs border-b border-dashed border-gray-300 cursor-pointer"
                  ><Icon name="document-file" class="w-3 h-3 mr-1" />
                  Download
                  <DotsVerticalIcon
                    class="ml-1 -mr-1 h-3 w-3"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div
                    class="px-1 py-1"
                    v-for="(Inv, index) in order.invoices"
                    :key="Inv.id"
                  >
                    <router-link target="_blank" :to="'/invoice/' + Inv.id">
                      <MenuItem v-slot="{ active }">
                        <button
                          :class="[
                            active
                              ? 'bg-violet-500 text-black'
                              : 'text-gray-500',
                            'group flex w-full items-center rounded-md px-2 py-1.5 text-xs',
                          ]"
                        >
                          Invoice {{ index + 1 }}
                        </button>
                      </MenuItem>
                    </router-link>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronRightIcon, DotsVerticalIcon } from "@heroicons/vue/solid";
import { DocumentIcon } from "@heroicons/vue/outline";
import Mymixin from "@/modules/mixins";

export default {
  name: "OrdersRow",
  props: ["order"],
  mixins: [Mymixin],
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronRightIcon,
    DotsVerticalIcon,
    DocumentIcon,
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss" scoped>
tr:hover td {
  @apply bg-gray-50;
}
.mobile-row {
  @apply lg:hidden pb-2 border-b border-gray-100 hover:bg-white;

  &:hover td {
    @apply bg-white;
  }
}
td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-theme-body;
  &.order-id,
  &.order-amount {
    @apply font-medium text-black;
  }
}

.options-btn {
  @apply text-theme-body-light opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
}

.options-list {
  @apply flex flex-col origin-top-right absolute -mt-4 top-full right-4 w-36 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10 divide-y divide-gray-100;

  .options-item {
    @apply flex space-x-2 items-center text-left text-xs text-theme-dark text-opacity-70 font-normal leading-4 px-3 py-2.5 hover:bg-gray-50;
    svg {
      @apply w-4 h-4;
    }
  }
}

.status {
  @apply xl:px-3 py-0 xl:py-1.5 bg-theme-bg rounded-full text-xxs xl:text-xs uppercase font-normal;

  &.delivered,
  &.finalized {
    color: #3f8445;
    background: rgba(121, 224, 162, 0.16);
  }

  &.shipped,
  &.shipping {
    color: #613f84;
    background: rgba(160, 121, 224, 0.16);
  }

  &.cancelled,
  &.void {
    color: #844b3f;
    background: rgba(224, 164, 121, 0.16);
  }

  @media (max-width: 1024px) {
    background: transparent !important;
  }
}
</style>
