<template>
  <div
    class="flex items-start flex-col 2xl:flex-row space-y-8 2xl:space-y-0 2xl:space-x-8"
  >
    <div class="w-full 2xl:w-auto flex-grow">
      <h3 class="title">Payments</h3>
      <!-- Pay Now  -->
      <Block icon="credit-card" title="Pay Now Methods">
        <template #action>
          <!-- <button class="card-btn" @click="openPaymentModal">
            <Icon name="plus" />
            <span>Add New Card</span>
          </button> -->
        </template>
        <Spinner :loading="cards.loading" />
        <p class="text-center text-red-600 mb-3">{{ msg }}</p>
        <div v-if="!cards.loading" class="grid grid-cols-3 gap-6">
          <div class="checkblock add-payment-card" @click="openPaymentModal">
            <div class="flex items-center space-x-2 mb-3">
              <span
                class="inline-flex flex-shrink-0 w-6 h-6 rounded-full border border-theme-light text-theme-light items-center justify-center"
              >
                <Icon class="w-4 h-4.5" name="plus"></Icon>
              </span>
            </div>
            <div class="flex flex-col h-full justify-between">
              <div class="block">
                <p class="item-name">Add New Credit Card</p>
                <RadioGroupDescription as="div" class="item-desc"
                  >Add a new credit card that can be used for payments at
                  checkout</RadioGroupDescription
                >
              </div>
            </div>
          </div>
          <div v-for="card in cards.data" :key="card.name">
            <div class="checkblock">
              <div class="flex flex-col justify-between h-full">
                <div class="flex items-start justify-between w-full">
                  <div>
                    <span class="item-nickname" v-if="card.nickname">
                      {{ card.nickname }}</span
                    >
                    <p class="item-name">
                      {{ card.cc_name }}
                    </p>
                    <p class="item-desc">
                      {{ "xxxx-xxxx-xxxx-" + card.cc_number.slice(-4) }}
                    </p>
                  </div>
                  <Menu as="div" class="relative text-right -mr-1.5">
                    <div>
                      <MenuButton class="options-btn">
                        <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                      </MenuButton>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems class="options-list">
                        <MenuItem
                          class="cursor-pointer"
                          @click="editCard(card)"
                        >
                          <a class="options-item">Edit Card</a>
                        </MenuItem>
                        <MenuItem
                          class="cursor-pointer"
                          @click="removeCard(card)"
                        >
                          <a class="options-item">Delete Card</a>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-2">
                    <span class=" text-theme-dark text-sm font-normal"
                      >{{ card.cc_exp.slice(0, 2) }}/{{
                        card.cc_exp.slice(-2)
                      }}</span
                    >
                    <span
                      v-if="card.expired == 1"
                      class="inline-flex items-center px-4 py-0.5 rounded-full text-tiny font-semibold uppercase bg-theme-red bg-opacity-20 text-theme-red"
                      >Expired</span
                    >
                  </div>
                  <div class="w-7 h-5 flex items-center justify-end">
                    <img
                      :src="
                        card.cc_number[0] == 4
                          ? '/images/card-providers/visa.png'
                          : card.cc_number[0] == 5
                          ? '/images/card-providers/master-card.png'
                          : card.cc_number[0] == 6
                          ? '/images/card-providers/Discover-logo.png'
                          : card.cc_number[0] == 3
                          ? '/images/card-providers/american-express.png'
                          : ''
                      "
                      class="max-w-full"
                    />
                  </div>
                </div>
              </div>
              <div
                :class="[
                  checked
                    ? 'border-theme-indigo border-opacity-50'
                    : 'border-transparent',
                  'absolute -inset-px rounded-lg border-2 pointer-events-none',
                ]"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </Block>
      <!-- Pay Later   -->
      {{ cards.payment_term?.isPaymentTerm }}
      <Block
        class="mt-5"
        icon="timer"
        title="Pay Later Methods"
        v-if="cards.payment_term?.isPaymentTerm"
      >
        <div class="grid grid-cols-3 gap-6">
          <div v-for="item in paylaterOptions" :key="item.name">
            <div class="checkblock">
              <div class="block">
                <p class="item-name">{{ item.name }}</p>
                <div class="item-desc">{{ item.desc }}</div>
                <div class="flex items-center justify-end">
                  <div class="w-auto h-7 flex items-center justify-end">
                    <img :src="item.provider" />
                  </div>
                </div>
              </div>
              <div
                :class="[
                  checked
                    ? 'border-theme-indigo border-opacity-50'
                    : 'border-transparent',
                  'absolute -inset-px rounded-lg border-2 pointer-events-none',
                ]"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </Block>
    </div>

    <!-- Add Modal -->
    <ModalFull
      :is-visible="showPaymentsModal"
      :reset-container="true"
      @close="showPaymentsModal = isSubmit = false"
      container-class="w-full max-w-2xl"
    >
      <div class="w-full bg-white">
        <div class="flex items-center justify-between bg-gray-100 px-5 py-5">
          <h3 class="flex items-center text-lg font-medium">
            <Icon name="add-card" class="w-6 h-6 mr-2" />
            <span>Add New Card</span>
          </h3>
          <button
            @click="showPaymentsModal = isSubmit = false"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="close" class="w-5 h-5 mr-1" />
          </button>
        </div>
        <div class="grid grid-cols-5 w-full divide-x divide-gray-100">
          <div class="flex-grow col-span-3">
            <div class="flex flex-col items-start text-left space-y-4 p-5">
              <div class="w-full space-y-1">
                <div class="flex items-center w-full space-x-2">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Card Nickname (Optional)</label
                  >
                  <tippy interactive>
                    <span class="cursor-pointer">
                      <Icon
                        name="info"
                        class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                      />
                    </span>
                    <template #content>
                      <p class="text-left px-1.5 text-xs">
                        A nickname helps you remember what a specific credit
                        card is for. For example: Main Street Store Card, Jones
                        Street Store Card.
                      </p>
                    </template>
                  </tippy>
                </div>

                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.nickname"
                    name=""
                    id=""
                    class="modal-input"
                    maxlength="32"
                    @keyup="checkValue()"
                  />
                </div>
              </div>

              <div class="w-full space-y-1">
                <label for="" class="text-xs text-theme-body leading-none"
                  >Card Number</label
                >
                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.cardNumber"
                    v-maska="generateCardNumberMask"
                    name=""
                    id=""
                    class="modal-input"
                  />
                  <span
                    class="pointer-events-none h-4 absolute right-2 top-1/2 -mt-2 w-8"
                  >
                    <img
                      :src="
                        addPaymentForm.cardNumber[0] == 4
                          ? '/images/card-providers/visa.png'
                          : addPaymentForm.cardNumber[0] == 5
                          ? '/images/card-providers/master-card.png'
                          : addPaymentForm.cardNumber[0] == 6
                          ? '/images/card-providers/Discover-logo.png'
                          : addPaymentForm.cardNumber[0] == 3
                          ? '/images/card-providers/american-express.png'
                          : null
                      "
                      class="max-w-full"
                    />
                  </span>
                </div>
                <span
                  v-if="
                    isSubmit &&
                      v$.addPaymentForm.cardNumber.$silentErrors.length > 0
                  "
                  class="text-theme-red font-semibold text-xs"
                >
                  <span
                    v-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'required'
                    "
                    >Required</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'numeric'
                    "
                    >Only Number</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'maxLength'
                    "
                    >Only 16 digits</span
                  >
                  <span
                    v-else-if="
                      v$.addPaymentForm.cardNumber.$silentErrors[0]
                        .$validator == 'minLength'
                    "
                    >Only 15 digits</span
                  >
                </span>
              </div>

              <div class="w-full space-y-1">
                <label for="" class="text-xs text-theme-body leading-none"
                  >Name on card</label
                >
                <div class="relative">
                  <input
                    type="text"
                    v-model="addPaymentForm.name"
                    name=""
                    id=""
                    class="modal-input"
                  />
                </div>
                <span
                  v-if="
                    isSubmit && v$.addPaymentForm.name.$silentErrors.length > 0
                  "
                  class="text-theme-red font-semibold text-xs"
                >
                  <span
                    v-if="
                      v$.addPaymentForm.name.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Required</span
                  >
                </span>
              </div>

              <div class="grid grid-cols-3 gap-5">
                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Expiration Month</label
                  >
                  <div class="relative">
                    <!-- <input
                      type="text"
                      v-model="addPaymentForm.expMonth"
                      name=""
                      id=""
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                    /> -->
                    <select
                      name="expMonth"
                      id="expMonth"
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                      v-model="addPaymentForm.expMonth"
                    >
                      <option
                        :value="val"
                        v-for="(val, index) in expMonthsValues"
                        :key="val"
                        >{{ expMonths[index] }}</option
                      >
                    </select>
                  </div>
                  <span
                    v-if="
                      isSubmit &&
                        v$.addPaymentForm.expMonth.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.expMonth.$silentErrors[0]
                          .$validator == 'required'
                      "
                      >Required</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.expMonth.$silentErrors[0]
                          .$validator == 'minValue'
                      "
                      >Current Month</span
                    >
                  </span>
                </div>

                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Expiration Year</label
                  >
                  <div class="relative">
                    <!-- <input
                      type="text"
                      v-model="addPaymentForm.expYear"
                      name=""
                      id=""
                      class="modal-input"
                    /> -->
                    <select
                      name="expYear"
                      id="expYear"
                      class="text-base w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:border-opacity-80 focus:ring-transparent"
                      v-model="addPaymentForm.expYear"
                    >
                      <option :value="exp" v-for="exp in expYears" :key="exp">{{
                        exp
                      }}</option>
                    </select>
                  </div>
                  <span
                    v-if="
                      isSubmit &&
                        v$.addPaymentForm.expYear.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.expYear.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Required</span
                    >
                  </span>
                </div>

                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >CVV</label
                  >
                  <div class="relative">
                    <input
                      type="text"
                      v-maska="generateCVVMask"
                      v-model="addPaymentForm.cvv"
                      name=""
                      id=""
                      class="modal-input"
                    />
                  </div>
                  <span
                    v-if="
                      isSubmit && v$.addPaymentForm.cvv.$silentErrors.length > 0
                    "
                    class="text-theme-red font-semibold text-xs"
                  >
                    <span
                      v-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'required'
                      "
                      >Required</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'numeric'
                      "
                      >Only Number</span
                    >
                    <span
                      v-else-if="
                        v$.addPaymentForm.cvv.$silentErrors[0].$validator ==
                          'maxLength'
                      "
                      >Only 03 digits</span
                    >
                  </span>
                </div>
              </div>

              <div class="w-full space-y-1 hidden">
                <input
                  type="checkbox"
                  v-model="addPaymentForm.save_card"
                  name="savePayment"
                  id="savePayment"
                  class="h-4 w-4 border-2 border-brand-gray border-opacity-50 rounded text-brand-secondary focus:ring-transparent cursor-pointer"
                  disabled
                />
                <label
                  for="savePayment"
                  class="text-xs text-theme-body leading-none ml-2 cursor-pointer select-none"
                  >Save for future payments</label
                >
              </div>

              <!-- <div
                class="flex items-center space-x-3 bg-theme-bg-shade bg-opacity-20 px-3 py-2 rounded text-sm text-theme-body w-full"
              >
                <Icon name="lock" class="w-4 h-4" />
                <span>We never store your credit card information</span>
              </div> -->
            </div>
            <div
              @click="AddNewPaymentCard"
              class="flex items-center justify-between px-5 pt-3 pb-6"
            >
              <Button icon="right-arrow" title="Add Card" />
            </div>
          </div>
          <div
            class="flex flex-col space-y-5 col-span-2 py-4 px-6 text-left text-sm"
          >
            <Icon name="user-sheild" class="w-12 h-12" />
            <h4 class="text-base font-semibold">Your information is safe</h4>
            <div class="space-y-3 text-theme-body-light pb-4 font-light">
              <p>
                We do not store your credit card information on our servers.
              </p>
              <p>
                Credit Card information is passed via a secure socket layer to
                our payment processor where your information is tokenized (a
                random number is generated to represent your card).
              </p>
              <p>
                No employee of Medusa has access to your complete credit card
                number.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalFull>

    <!-- Edit Modal -->
    <ModalFull
      :is-visible="showPaymentsEditModal"
      :reset-container="true"
      @close="showPaymentsEditModal = false"
      container-class="w-full max-w-2xl"
    >
      <div class="w-full bg-white">
        <div class="flex items-center justify-between bg-gray-100 px-5 py-5">
          <h3 class="flex items-center text-lg font-medium">
            <Icon name="add-card" class="w-6 h-6 mr-2" />
            <span>Update Card</span>
          </h3>
          <button
            @click="showPaymentsEditModal = false"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="close" class="w-5 h-5 mr-1" />
          </button>
        </div>
        <div class="grid grid-cols-5 w-full divide-x divide-gray-100">
          <div class="flex-grow col-span-3">
            <div class="flex flex-col items-start text-left space-y-4 p-5">
              <div class="w-full space-y-1">
                <div class="flex items-center w-full space-x-2">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Card Nickname (Optional)</label
                  >
                  <tippy interactive>
                    <span class="cursor-pointer">
                      <Icon
                        name="info"
                        class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                      />
                    </span>
                    <template #content>
                      <p class="text-left px-1.5 text-xs">
                        A nickname helps you remember what a specific credit
                        card is for. For example: Main Street Store Card, Jones
                        Street Store Card.
                      </p>
                    </template>
                  </tippy>
                </div>

                <div class="relative">
                  <input
                    type="text"
                    v-model="this.selectedEditCard.nickname"
                    name=""
                    id=""
                    @keyup="checkValueEdit()"
                    class="modal-input"
                    maxlength="32"
                  />
                </div>
              </div>

              <div class="flex items-streach gap-5">
                <div class="w-full space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Card Number</label
                  >
                  <div class="relative">
                    <input
                      type="text"
                      :value="
                        'xxxx-xxxx-xxxx-' + selectedEditCard.cc_number.slice(-4)
                      "
                      name=""
                      disabled
                      id=""
                      class="disabled-input"
                    />
                  </div>
                </div>
                <div class="w-48 space-y-1">
                  <label for="" class="text-xs text-theme-body leading-none"
                    >Exp (Month/year)</label
                  >
                  <div class="relative">
                    <input
                      type="text"
                      :value="
                        selectedEditCard.cc_exp.slice(0, 2) +
                          '/' +
                          selectedEditCard.cc_exp.slice(-2)
                      "
                      disabled
                      name=""
                      id=""
                      class="disabled-input"
                    />
                  </div>
                </div>
              </div>

              <div class="w-full space-y-1">
                <label for="" class="text-xs text-theme-body leading-none"
                  >Name on card</label
                >
                <div class="relative">
                  <input
                    type="text"
                    v-model="this.selectedEditCard.cc_name"
                    disabled
                    name=""
                    id=""
                    class="disabled-input"
                  />
                </div>
              </div>
            </div>
            <div
              @click="UpdatePaymentCard"
              class="flex items-center justify-between px-5 pt-3 pb-6"
            >
              <Button icon="right-arrow" title="Update Card" />
            </div>
          </div>
          <div
            class="flex flex-col space-y-5 col-span-2 py-4 px-6 text-left text-sm"
          >
            <Icon name="user-sheild" class="w-12 h-12" />
            <h4 class="text-base font-semibold">Your information is safe</h4>
            <div class="space-y-3 text-theme-body-light pb-4 font-light">
              <p>
                We do not store your credit card information on our servers.
              </p>
              <p>
                Credit Card information is passed via a secure socket layer to
                our payment processor where your information is tokenized (a
                random number is generated to represent your card).
              </p>
              <p>
                No employee of Medusa has access to your complete credit card
                number.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalFull>

    <!-- Codnrim modal -->
    <Confirm
      :isVisible="confrim"
      cancelButton="Cancel"
      confirmButton="Yes"
      @cancel="confrim = false"
      @confirm="confrimToDelete"
    >
      <p>
        Are you sure you want to remove this credit card ending in
        {{ this.selectedCard.cc_number.slice(-4) }}?"
      </p>
    </Confirm>
    <!--  Confrim Modal -->
  </div>
</template>

<script>
import Block from "../../checkout/modules/Block.vue";
import Checkout from "@/modules/Checkout";
import { ref, inject, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  numeric,
  minLength,
  maxLength,
  minValue,
} from "@vuelidate/validators";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { DotsVerticalIcon } from "@heroicons/vue/solid";

export default {
  name: "Payments",
  components: {
    Block,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
  },
  data() {
    return {
      v$: useVuelidate(),
      form_URL: "",
      msg: "",
      addPaymentForm: {
        cardNumber: "",
        name: "",
        expMonth: "",
        expYear: "",
        cvv: "",
      },
      selectedCard: "",
      selectedEditCard: "",
      confrim: false,
      isSubmit: false,
      expMonths: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      expMonthsValues: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  validations() {
    return {
      addPaymentForm: {
        cardNumber: {
          required,
          // numeric,
          // minLength: minLength(18),
          // maxLength: maxLength(19),
        },
        name: { required },
        expMonth: {
          required,
          numeric,
          minValue: minValue(this.checkingMonth()),
        },
        expYear: {
          required,
          numeric,
        },
        cvv: {
          required,
          numeric,
          minLength: minLength(0),
          // maxLength: maxLength(3),
        },
      },
    };
  },
  methods: {
    checkValue() {
      this.addPaymentForm["nickname"] = this.addPaymentForm["nickname"].replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
    },

    checkValueEdit() {
      this.selectedEditCard["nickname"] = this.selectedEditCard[
        "nickname"
      ].replace(/[^a-zA-Z0-9 ]/g, "");
    },
    checkingMonth() {
      return new Date().getFullYear() == this.addPaymentForm.expYear
        ? new Date().getMonth() + 1
        : 1;
    },
    openPaymentModal() {
      this.msg = "";
      this.axios.authApi
        .get("/customerVaultStepOne")
        .then((r) => (this.form_URL = r.data.data["form-url"]));
      this.showPaymentsModal = true;
    },
    removeCard(card) {
      this.confrim = true;
      this.selectedCard = card;
    },
    editCard(card) {
      console.log(card);
      this.selectedEditCard = card;
      this.showPaymentsEditModal = true;
    },
    confrimToDelete() {
      this.axios.authApi
        .get("/deleteCard/" + this.selectedCard.id)
        .then((r) => {
          this.getCreditCards();
          this.confrim = this.isSubmit = false;
        });
    },
    closeModal() {
      this.addPaymentForm = {
        cardNumber: "",
        name: "",
        nickname: "",
        expMonth: "",
        expYear: "",
        cvv: "",
      };
      this.isSubmit = false;
      this.showPaymentsModal = false;
    },
    AddNewPaymentCard() {
      this.isSubmit = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        let dataToPost = {
          billing_name: this.addPaymentForm.name,
          nickname: this.addPaymentForm.nickname,
          billing_number: this.addPaymentForm.cardNumber,
          cvv: this.addPaymentForm.cvv,
          billing_exp:
            this.addPaymentForm.expMonth + this.addPaymentForm.expYear,
          formUrl: this.form_URL,
        };
        this.cards.loading = true;
        console.log("data to post ", dataToPost);
        // return ;
        this.axios.authApi
          .post("/customerVaultStepTwo", dataToPost)
          .then((r) => {
            if (r.data.status) {
              this.getCreditCards();
              this.isSubmit = false;
            } else {
              this.cards.loading = false;
              this.msg = r.data.error;
            }
          });
        this.showPaymentsModal = false;
        this.addPaymentForm = {
          cardNumber: "",
          name: "",
          nickname: "",
          expMonth: "",
          expYear: "",
          cvv: "",
        };
        // this.nmiStepOne(data, 1);
      }
    },

    UpdatePaymentCard() {
      this.axios.authApi
        .get(
          "/CCNameUpdate/" +
            this.selectedEditCard.id +
            "/?nickname=" +
            this.selectedEditCard.nickname
        )
        .then((r) => {
          if (r.data.status) {
            this.getCreditCards();
            this.isSubmit = false;
          } else {
            this.cards.loading = false;
            this.msg = r.data.error;
          }
        });
      this.showPaymentsEditModal = false;
    },
  },
  computed: {
    getCardType() {
      let number = this.addPaymentForm.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? "#### ###### #####"
        : "#### #### #### ####";
    },
    generateCVVMask() {
      return this.getCardType === "amex" ? "####" : "###";
    },
  },
  setup() {
    const axios = inject("axios");
    const { cards, getCreditCards } = Checkout();
    const showPaymentsModal = ref(false);
    const showPaymentsEditModal = ref(false);

    getCreditCards();
    const paylaterOptions = [
      // {
      //   id: 1,
      //   name: "Pay Later",
      //   provider: "/images/card-providers/behalf.png",
      //   desc: "Powered by Behalf",
      // },
      {
        id: 1,
        name: "Pay Later",
        provider: "/images/card-providers/medusa.png",
        desc: "Payment Terms",
      },
      // {
      //   id: 2,
      //   name: "Pay At Pick Up",
      //   provider: "/images/card-providers/medusa.png",
      //   desc: "For Local Pickup Only",
      // },
    ];

    const expYears = computed(() => {
      const years = [];
      const currentYear = new Date().getFullYear();
      let updatedYear = currentYear;

      while (updatedYear < currentYear + 25) {
        years.push(updatedYear);
        updatedYear++;
      }
      // console.log(years);
      return years;
    });

    return {
      axios,
      cards,
      paylaterOptions,
      showPaymentsModal,
      showPaymentsEditModal,
      getCreditCards,
      expYears,
    };
  },
};
</script>

<style lang="scss" scoped>
.options-btn {
  @apply text-theme-body-light opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
}

.options-list {
  @apply flex flex-col origin-top-right absolute -mt-4 top-full right-4 w-32 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10 divide-y divide-gray-100;

  .options-item {
    @apply block text-center text-xs text-theme-dark text-opacity-70 font-normal leading-4 px-3 py-2.5 hover:bg-gray-50;
  }
}
h3.title {
  @apply text-xl font-semibold text-theme-secondary mb-6;
}

.card-btn {
  @apply rounded text-xs px-3 py-2 leading-none font-medium text-white bg-theme-secondary inline-flex items-center uppercase tracking-wider;
  svg {
    @apply w-4 h-4 -mt-0.5 mr-1;
  }
}

.disabled-input {
  @apply border-theme-border bg-gray-50 text-md w-full border rounded  px-3 py-2 font-light;
}

.modal-input {
  @apply text-md font-light w-full border border-theme-body border-opacity-50 rounded focus:outline-none pl-3 pr-6 py-2 focus:border-theme-body focus:ring-transparent;
}

.checkblock {
  @apply relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4  focus:outline-none select-none h-full;
  // hover:border-gray-400 cursor-pointer
  .checkmark-label {
    @apply flex w-full justify-end -mb-2;

    .checkmark {
      @apply border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center;

      &.active {
        @apply bg-theme-indigo border-theme-indigo text-white;
      }

      &.inactive {
        @apply bg-white border-theme-border;
      }
    }
  }
  .item-name {
    @apply text-base font-semibold text-theme-secondary mb-1;

    &.no-margin {
      @apply mb-0;
    }
  }

  .item-nickname {
    @apply text-xs font-normal text-theme-body mb-1;
  }

  .item-desc {
    @apply block text-sm text-theme-secondary font-normal text-opacity-75 w-full mb-3;
  }
}
.add-payment-card {
  @apply hover:bg-gray-100 hover:bg-opacity-20 cursor-pointer;
}
</style>
