<template>
  <div>
    <div class="flex items-center justify-between mb-8">
      <h3 class="text-xl font-semibold text-theme-secondary leading-none">
        Users
      </h3>
      <button
        v-if="users.activeUser.role === 'Owner'"
        class="card-btn flex items-center -mb-1 uppercase text-gray-400"
        @click="showAddModal = true, resetForm()"
      >
        <Icon name="plus" />
        <span>ADD NEW User</span>
      </button>
    </div>
    <table class="users-table">
      <thead class="bg-white">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Last Login</th>
          <th scope="col">Role</th>
          <th scope="col" width="300"></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="user in users.data.users" :key="user.id">
          <td class="name">
            <div class="flex items-center flex-grow space-x-5">
              <div
                class="flex items-center justify-center rounded-full w-10 h-10 text-indigo-500 bg-indigo-100 flex-shrink-0 relative"
              >
                <span class="text-base font-normal">{{
                  userInitials(user.name)
                }}</span>
                <span v-if="user.role == 'Owner'" class="indicator">
                  <svg
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-2 h-2"
                  >
                    <path
                      d="M6.25 0L4.79167 1.45833L3.625 0L2.45833 1.45833L1 0V3.79167H6.25V0ZM1.58333 5.54167H5.66667C5.82138 5.54167 5.96975 5.48021 6.07915 5.37081C6.18854 5.26142 6.25 5.11304 6.25 4.95833V4.375H1V4.95833C1 5.11304 1.06146 5.26142 1.17085 5.37081C1.28025 5.48021 1.42862 5.54167 1.58333 5.54167Z"
                      fill="#BF8D41"
                    />
                  </svg>
                </span>
              </div>
              <div class="flex flex-col text-sm font-normal leading-none">
                <p class="text-theme-secondary mb-0.5">{{ user.name }}</p>
                <!-- <span class="font-light text-theme-body">{{ user.email }}</span> -->
              </div>
            </div>
          </td>
          <td>{{ user.email }}</td>
          <td>
            {{
              user.last_login_at
                ? moment(user.last_login_at).format("MMM Do YYYY")
                : "Pending"
            }}
          </td>
          <td>
            <!-- <span class="role">{{ user.admin ? "Admin" : "Manager" }}</span> -->
            <span class="role">{{ user.role }}</span>
          </td>
          <td>
            <div
              v-if="
                users.activeUser.role == 'Owner' ||
                  (users.activeUser.role == 'Manager' && user.loggedIn)
              "
              class="actions"
            >
              <button v-if="!user.admin == 1" @click="deleteUser(user.id)">
                <Icon
                  v-tippy="{ content: 'Deletes', placement: 'top' }"
                  name="delete"
                  class="w-5 h-5"
                />
              </button>
              <button
                @click="openEditModal(user)"
                v-tippy="{ content: 'Edit', placement: 'top' }"
              >
                <Icon name="edit" class="w-5 h-5" />
              </button>
              <!-- <button
                @click="sendResetPassword(user.email)"
                v-tippy="{ content: 'Reset', placement: 'top' }"
              >
                <Icon name="reload" class="w-5 h-5" />
              </button> -->
            </div>
            <!-- <div v-else-if="user.role== 'Owner' || user.loggedIn" >asdasd</div> -->
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Add Modal -->
    <ModalFull
      :is-visible="showAddModal"
      :reset-container="true"
      @close="showAddModal = isSubmit = false"
      container-class="w-full max-w-4xl"
    >
      <div class="w-full bg-white">
        <div class="flex items-center justify-between bg-gray-100 px-5 py-5">
          <h3 class="flex items-center text-lg font-medium">
            <Icon name="user-add" class="w-6 h-6 mr-2" /> <span>Add User</span>
          </h3>
          <button
            @click="showAddModal = isSubmit = false"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="close" class="w-5 h-5 mr-1" />
          </button>
        </div>
        <div class="grid grid-cols-5 w-full divide-x divide-gray-100">
          <div class="invite-form flex-grow col-span-3">
            <div class="grid grid-cols-2 gap-5">
              <div class="form-item">
                <label for="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Enter firstname"
                  v-model="form.name"
                />
                <span
                  v-if="isSubmit && v$.form.name.$silentErrors.length > 0"
                  class="text-theme-red font-semibold text-xs"
                >
                  <span
                    v-if="
                      v$.form.name.$silentErrors[0].$validator == 'required'
                    "
                    >Required</span
                  >
                </span>
              </div>

              <div class="form-item">
                <label for="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Enter lastname"
                  v-model="form.last_name"
                />
                <span
                  v-if="isSubmit && v$.form.name.$silentErrors.length > 0"
                  class="text-theme-red font-semibold text-xs"
                >
                </span>
              </div>
            </div>

            <div class="form-item" id="email">
              <label for="useremail">Email</label>
              <input
                type="text"
                name="useremail"
                id="useremail"
                placeholder="Enter user email"
                v-model="form.email"
              />
              <span
                v-if="isSubmit && v$.form.email.$silentErrors.length > 0"
                class="text-theme-red font-semibold text-xs"
              >
                <span
                  v-if="v$.form.email.$silentErrors[0].$validator == 'required'"
                  >Required</span
                >
              </span>
            </div>
            <div class="form-item">
              <label for="phone">Role</label>
              <input
                class="bg-gray-100"
                type="text"
                name="role"
                id="role"
                placeholder="Enter user Role"
                v-model="form.role"
                disabled
              />
            </div>
            <div class="form-item">
              <label for="phone">Phone</label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter user phone"
                v-model="form.phone_number"
              />
            </div>

            <div class="w-full space-y-4 py-4">
              <div @click="addUserForm()">
                <Button icon="right-arrow" title="SAVE DETAILS" />
              </div>
            </div>
          </div>
          <div
            class="flex flex-col space-y-5 col-span-2 py-4 px-6 text-left text-sm"
          >
            <Icon name="user-sheild" class="w-12 h-12" />
            <h4 class="text-base font-semibold">Important Note</h4>
            <div class="space-y-3 text-theme-body-light">
              <p>
                New users will have the ability to see your account history as
                well as check out using your saved payment information.
              </p>
              <p>
                However, they will not be able to any complete credit card
                numbers, just the last 4 digits.
              </p>
              <p>
                Only you have the ability to add more users and delete users on
                this account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalFull>

    <!-- Edit Modal -->
    <ModalFull
      :is-visible="showEditModal"
      :reset-container="true"
      @close="showEditModal = false"
      container-class="w-full max-w-md"
    >
      <div class="w-full max-w-md">
        <div class="flex items-center justify-between bg-gray-100 px-4 py-3">
          <h3 class="text-lg font-medium">Edit User</h3>

          <button
            v-if="users.activeUser.role == 'Owner'"
            @click="deleteUser(form.id)"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="delete" class="w-4 h-4 mr-1" /> Delete User
          </button>
          <button
            v-else
            @click="showEditModal = false"
            class="flex items-center uppercase font-medium text-xs cursor-pointer text-gray-400"
          >
            <Icon name="close" class="w-4 h-4 mr-1" />
          </button>
        </div>

        <div class="invite-form">
          <div class="grid grid-cols-2 gap-5">
            <div class="form-item">
              <label for="firstname">First Name</label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Enter firstname"
                v-model="form.name"
              />
              <span
                v-if="isSubmit && v$.form.name.$silentErrors.length > 0"
                class="text-theme-red font-semibold text-xs"
              >
                <span
                  v-if="
                    v$.form.name.$silentErrors[0].$validator == 'required'
                  "
                  >Required</span
                >
              </span>
            </div>

            <div class="form-item">
              <label for="lastname">Last Name</label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Enter lastname"
                v-model="form.last_name"
              />
              <span
                v-if="isSubmit && v$.form.name.$silentErrors.length > 0"
                class="text-theme-red font-semibold text-xs"
              >
              </span>
            </div>
          </div>

          <div class="form-item">
            <label for="useremail">Email</label>
            <input
              type="text"
              name="useremail"
              id="useremail"
              placeholder="Enter user email"
              v-model="form.email"
            />
            <span
              v-if="v$.form.email.$silentErrors.length > 0"
              class="text-theme-red font-semibold text-xs"
            >
              <span
                v-if="v$.form.email.$silentErrors[0].$validator == 'required'"
                >Required</span
              >
            </span>
          </div>
          <div class="form-item">
            <label for="phone">Role</label>
            <input
              class="bg-gray-100"
              type="text"
              name="role"
              id="role"
              placeholder="Enter user role"
              v-model="form.role"
              disabled
            />
            <span
              v-if="v$.form.role.$silentErrors.length > 0"
              class="text-theme-red font-semibold text-xs"
            >
              <span
                v-if="v$.form.role.$silentErrors[0].$validator == 'required'"
                >Required</span
              >
            </span>
          </div>

          <div class="form-item">
            <label for="phone">Phone</label>
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Enter user phone"
              v-model="form.phone_number"
            />
          </div>

          <div class="w-full space-y-4 pt-4">
            <div @click="sendResetPassword(form.email)">
              <Button
                icon="reload"
                title="SEND PASSWORD RESET LINK"
                :outline="true"
              />
            </div>
            <div @click="editUserForm">
              <Button icon="right-arrow" title="SAVE DETAILS" />
            </div>
            <Button
              title="CANCEL"
              class="no-bg"
              @click="showEditModal = false"
            />
          </div>
        </div>
      </div>
    </ModalFull>
  </div>
</template>

<script>
import Account from "@/modules/Account";
import { ref, inject, onMounted } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";

export default {
  name: "Users",
  data() {
    return {
      v$: useVuelidate(),
      isSubmit: false,
      form: {
        name: "",
        last_name: "",
        email: "",
        role: "Manager",
        phone_number: "",
      },
      //  selectedRow:'',
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        role: { required },
      },
    };
  },
  methods: {
    moment: function(date) {
      return moment(date);
    },
    addUserForm() {
      this.isSubmit = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        this.axios.authApi.post("/account/storeUser", this.form).then((res) => {
          if (res.data.status) {
            this.form = { name: "", email: "", phone: "" };
            this.isSubmit = false;
            this.showAddModal = false;
            this.getUsers();
          } else {
            this.showMsg(res.data.error);
          }
        });
      }
    },
    showMsg(d) {
      for (const key in d) {
        let p = document.getElementById(key);
        const msg = document.createElement("p");
        msg.innerText = d[key];
        msg.className = "text-red-400 text-sm alert-msg";
        // Append to parent:
        p.appendChild(msg);
      }
      setTimeout(() => {
        let row = document.getElementsByClassName("alert-msg");
        row.forEach((element) => {
          element.remove();
        });
      }, 2000);
    },
    openEditModal(r) {
      this.form = { ...r };
      this.showEditModal = true;
    },
    editUserForm() {
      const { name, email, role, phone_number } = this.form;
      if (name && email && role) {
        this.axios.authApi
          .post("/account/updateUser/" + this.form.id, this.form)
          .then((res) => {
            this.form = { name: "", email: "", phone: "" };
            this.showEditModal = false;
            this.getUsers();
          });
      }
    },
    deleteUser(id) {
      this.axios.authApi.get("/account/deleteUser/" + id).then((res) => {
        this.showEditModal = false;
        this.getUsers();
      });
    },
    sendResetPassword(email) {
      console.log({ email });
      this.axios.api.post("/forgot-password", { email }).then((res) => {
        this.showEditModal = false;
        // this.getUsers();
      });
    },
    resetForm(){
      this.form.name         = "",
      this.form.last_name    = "",
      this.form.email        = "",
      this.form.role         = "Manager",
      this.form.phone_number = ""
    }
  },
  // watch:{
  //   users(){
  //     console.log("asd")
  //   }
  // },
  setup() {
    const axios = inject("axios");
    const { users, getUsers } = Account();
    getUsers();
    const showAddModal = ref(false);
    const showEditModal = ref(false);

    const userInitials = (name) => {
      return name
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    };
    return {
      axios,
      users,
      getUsers,
      showAddModal,
      showEditModal,
      userInitials,
    };
  },
};
</script>

<style lang="scss" scoped>
h3.title {
  @apply text-xl font-semibold text-theme-secondary mb-6;
}

.invite-form {
  @apply w-full bg-white p-6 pb-2 space-y-6 text-left;

  label {
    @apply block text-sm font-medium text-theme-body leading-none mb-3;
  }

  input {
    @apply text-md font-light placeholder-gray-400 w-full border border-theme-body border-opacity-50 rounded focus:outline-none px-3 py-2.5 focus:border-theme-body focus:ring-transparent;
  }
}

.users-table {
  @apply min-w-full divide-y divide-gray-200;

  th {
    @apply relative px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }

  td {
    @apply px-6 py-4 whitespace-nowrap text-sm text-theme-body;
    &.order-id,
    &.order-amount {
      @apply font-medium text-black;
    }
  }

  .options-btn {
    @apply text-theme-body-light opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
  }

  .options-list {
    @apply flex flex-col origin-top-right absolute -mt-4 top-full right-4 w-36 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10 divide-y divide-gray-100;

    .options-item {
      @apply flex space-x-2 items-center text-left text-xs text-theme-dark text-opacity-70 font-normal leading-4 px-3 py-2.5 hover:bg-gray-50;
      svg {
        @apply w-4 h-4;
      }
    }
  }
}

.indicator {
  @apply flex items-center justify-center w-4 h-4 bg-yellow-300 rounded-full border border-white absolute -right-1 -bottom-0.5;
}

.role {
  background: #f8f8f8;
  color: #a3b0b8;
  @apply text-xs uppercase text-center w-24 rounded-md px-2 py-3 leading-none flex-shrink-0 block font-medium;
}
.actions {
  @apply flex items-center justify-end space-x-5;
  button {
    @apply text-theme-body opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
  }
}
</style>
