<template>
  <div>
    <div class="flex items-center space-x-4 mb-6">
      <h3 class="text-xl font-semibold text-theme-secondary leading-none">
        Shipping Locations
      </h3>
      <button class="card-btn flex items-center -mb-1" @click="show">
        <Icon name="plus" />
        <span>ADD A LOCATION</span>
      </button>
    </div>
    <Spinner :loading="addresses.loading" />
    <div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-6">
      <div
        v-for="loc in addresses.data"
        :key="loc.city"
        class="bg-white rounded shadow-box px-5 py-6 flex items-start"
      >
        <div
          class="flex items-center justify-center flex-shrink-0 bg-theme-purple-light text-theme-purple-dark w-10 h-10 rounded-full"
        >
          <Icon name="shop" class="w-6 h-6" />
        </div>
        <div class="flex-grow pl-5">
          <div class="flex items-start justify-between">
            <div class="block w-10/12">
              <!-- <span v-if="loc.nickname" class="block text-sm text-theme-body leading-6 font-light"
                >{{ loc.nickname }}<br />
              </span> -->
              <p class="block text-md text-theme-secondary font-semibold">
                {{ loc.nickname ? loc.nickname : loc.company_name }}
              </p>
              <span class="block text-sm text-theme-body leading-6 font-light"
                >{{ loc.address1 }}<br />
                <span v-if="loc.address2">{{ loc.address2 }}<br /></span>
                {{ loc.city }}, {{ loc.state }} {{ loc.zip_code }}</span
              >
            </div>
            <Menu as="div" class="relative text-right">
              <div>
                <MenuButton class="options-btn">
                  <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <!-- "updateAddressModal = true" -->
                <MenuItems class="options-list">
                  <MenuItem
                    class="cursor-pointer"
                    @click="edit(loc.trade_gecko_id, loc.nickname)"
                  >
                    <a class="options-item">Edit Location</a>
                  </MenuItem>
                  <MenuItem
                    class="cursor-pointer"
                    @click="delAddress(loc.trade_gecko_id)"
                  >
                    <a class="options-item">Delete Location</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div
            v-if="loc.is_approved == 1"
            class="flex items-center justify-between text-xs font-medium pt-5 pr-5"
          >
            <span
              class="'inline-flex items-center px-4 py-0.5 rounded-full text-tiny font-semibold uppercase bg-theme-green-light text-theme-green-dark'"
              >APPROVED</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Address modal -->
    <ModalFull
      :is-visible="showAddressModal"
      :reset-container="true"
      @close="showAddressModal = false"
    >
      <Block
        icon="shop"
        title="Add New Location"
        :streachActions="true"
        class="modal wd-50"
      >
        <template #action>
          <div
            class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4">
              <button
                class="font-medium text-sm uppercase border-b-2 border-transparent px-1"
                :class="
                  currentStep === 1 ? 'border-black text-black' : 'cursor-auto'
                "
                v-if="currentStep < 3 && currentStep != 1.2"
              >
                <!-- hover:border-black hover:text-black -->
                <!-- @click="currentStep = 1" -->
                Step 1
              </button>
              <button
                class="font-medium text-sm uppercase border-b-2 border-transparent px-1"
                :class="
                  currentStep === 2 ? 'border-black text-black' : 'cursor-auto'
                "
                v-if="currentStep < 3 && currentStep != 1.2"
                :disabled="isDisabled"
              >
                <!-- @click="currentStep = 2" -->
                Step 2
              </button>
            </div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="showAddressModal = false && currentStep === 1"
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div class="grid grid-cols-2 gap-8 " v-show="currentStep === 1">
            <div class="col-span-2 input">
              <Input
                v-model:modelValue="address.nickname"
                @keyup="checkValue()"
                placeholder="Location Name"
                maxlength="32"
              >
                <div class="flex items-center w-full space-x-2">
                  <label for="">Location Name</label>
                  <tippy interactive>
                    <span class="cursor-pointer">
                      <Icon
                        name="info"
                        class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                      />
                    </span>
                    <template #content>
                      <p class="text-left px-1.5 text-xs">
                        A location name will change the name of your location on
                        the shipping label. This is helpful when you have a
                        company name but stores with different names.
                        <br />
                        By default, if you don't enter a location name,
                        <b>{{ company_name }}</b> will be the location on the
                        shipping label.
                      </p>
                    </template>
                  </tippy>
                </div>
              </Input>
            </div>
            <div class="col-span-2 input">
              <Input
                padding="4px"
                label="Address Line 1"
                v-model:modelValue="address.address1"
                placeholder="Address Line 1"
              />
              <span
                v-if="isSubmit && v$.address.address1.$silentErrors.length > 0"
                class="flex text-left text-sm font-light text-red-400 pt-2"
              >
                <span
                  v-if="
                    v$.address.address1.$silentErrors[0].$validator ==
                      'required'
                  "
                  >Address is required.</span
                >
              </span>
            </div>
            <div class="col-span-2 input">
              <Input
                label="Address Line 2"
                v-model:modelValue="address.address2"
                placeholder="Address Line 2"
              />
            </div>

            <div class="col-span-2 grid grid-cols-3 gap-8">
              <div class="input">
                <Input
                  label="City"
                  v-model:modelValue="address.city"
                  placeholder="City"
                />
                <span
                  v-if="isSubmit && v$.address.city.$silentErrors.length > 0"
                  class="flex text-left text-sm font-light text-red-400 pt-2"
                >
                  <span
                    v-if="
                      v$.address.city.$silentErrors[0].$validator == 'required'
                    "
                    >City is required.</span
                  >
                </span>
              </div>

              <div>
                <Listbox
                  as="div"
                  v-model="address.state"
                  class="store-switcher"
                >
                  <div>
                    <ListboxLabel>State</ListboxLabel>
                    <ListboxButton class="group ss-btn">
                      <span class="flex w-full justify-between items-center">
                        <span
                          class="flex min-w-0 items-center justify-between space-x-3"
                        >
                          <div
                            class="flex items-center justify-center rounded-full w-8 h-8 text-brand-indigo bg-purple-100 flex-shrink-0 order-bg"
                          >
                            <Icon name="shop" class="w-5 h-5" />
                          </div>
                          <span
                            class="flex-1 flex flex-col min-w-0"
                            v-if="address.state"
                          >
                            <span
                              class="text-brand-secondary text-sm font-medium truncate"
                              >{{
                                address.state ? address.state.state_name : null
                              }}</span
                            >
                          </span>
                        </span>
                        <SelectorIcon
                          class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <ListboxOptions
                      class="item-opts"
                      style="height:110px; overflow:auto;"
                    >
                      <ListboxOption
                        as="div"
                        v-for="opt in st.data"
                        :key="opt.id"
                        :value="opt"
                        @click="selectZipcode(opt.state)"
                        class="group item-opt"
                      >
                        <span
                          class="flex min-w-0 items-center justify-between space-x-3"
                        >
                          <span class="flex-1 flex flex-col min-w-0">
                            <span class="name"
                              >{{ opt.state_name
                              }}{{ " (" + opt.state + ")" }}</span
                            >
                          </span>
                        </span>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </Listbox>
                <span
                  v-if="isSubmit && v$.address.state.$silentErrors.length > 0"
                  class="flex text-left text-sm font-light text-red-400 pt-2"
                >
                  <span
                    v-if="
                      v$.address.state.$silentErrors[0].$validator == 'required'
                    "
                    >State is required.</span
                  >
                </span>
              </div>

              <div>
                <Combobox
                  :disabled="!address.state"
                  v-model="address.zip_code"
                  :value="address.zip_code ? address.zip_code : ''"
                  class="store-switcher"
                  nullable
                >
                  <div class="relative mt-1">
                    <ComboboxLabel>Zip Code</ComboboxLabel>
                    <div
                      class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                    >
                      <ComboboxInput
                        :disabled="!address.state"
                        class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        :displayValue="(zip) => zip?.zip_code"
                        @change="getZipcodes(state_code, $event.target.value)"
                      />
                      <ComboboxButton
                        class="absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </ComboboxButton>
                    </div>
                    <TransitionRoot
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      @after-leave="query = ''"
                    >
                      <ComboboxOptions
                        class="z-10 absolute max-h-28 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <!-- <div
                        v-if="zipcode.data.data.length === 0 && query !== ''"
                        class="relative cursor-default select-none py-2 px-4 text-gray-700"
                      >
                        Nothing found.
                      </div> -->

                        <ComboboxOption
                          v-for="zip in zipcode.data.data"
                          as="template"
                          :key="zip.id"
                          :value="zip"
                          v-slot="{ selected, active }"
                        >
                          <li
                            class="relative cursor-default select-none py-2 pl-10 pr-4"
                            :class="{
                              'bg-teal-600 text-theme-secondary': active,
                              'text-theme-body': !active,
                            }"
                          >
                            <span
                              class="block truncate"
                              :class="{
                                'font-medium': selected,
                                'font-normal': !selected,
                              }"
                            >
                              {{ zip.zip_code }}
                            </span>
                            <span
                              v-if="selected"
                              class="absolute inset-y-0 left-0 flex items-center pl-3"
                              :class="{
                                'text-brand-indigo': active,
                                'text-teal-600': !active,
                              }"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ComboboxOption>
                      </ComboboxOptions>
                    </TransitionRoot>
                  </div>
                </Combobox>
                <span
                  v-if="
                    isSubmit && v$.address.zip_code.$silentErrors.length > 0
                  "
                  class="flex text-left text-sm font-light text-red-400 pt-2"
                >
                  <span
                    v-if="
                      v$.address.zip_code.$silentErrors[0].$validator ==
                        'required'
                    "
                    >Zip code is required.</span
                  >
                  <span
                    v-else-if="
                      v$.address.zip_code.$silentErrors[0].$validator ==
                        'minLength'
                    "
                    >Zip code must be atleast 4 characters long.</span
                  >
                </span>
              </div>
            </div>
            <div class="col-span-2 flex justify-end">
              <div class="w-full flex-shrink-0" @click="save">
                <Button title="Continue" />
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-8" v-show="currentStep === 2">
            <div class="flex items-center justify-between">
              <p class="text-base font-medium text-brand-body">
                Submit required documents
              </p>
              <span
                class="flex items-center uppercase font-normal text-xs cursor-pointer text-brand-body"
                @click="currentStep = 1"
                ><ChevronLeftIcon class="w-4 h-4 ml-1" /> Go Back</span
              >
            </div>
            <div class="flex flex-col space-y-7">
              <div v-for="(lic, i) in address.licenses" :key="lic.key">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <span
                      v-if="files[lic.key] == null"
                      class="w-5 h-5 border bordergray-100 rounded-full flex-shrink-0 mr-3.5"
                    ></span>
                    <span
                      v-else
                      class="w-5 h-5 border border-green-400 flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0 mr-3.5"
                    >
                      <CheckIcon class="w-3.5 h-3.5" />
                    </span>
                    <p class="text-md text-brand-body">{{ lic.name }}</p>
                  </div>
                  <div class="flex items-center justify-end space-x-5">
                    <!-- @click="handle(lic.key)" -->
                    <input
                      @click="handle($event)"
                      @change="handleFileUpload($event, lic.key)"
                      type="file"
                      :id="`doc-${i}-file`"
                      class="absolute w-0 h-0 opacity-0"
                    />
                    <!-- v-if="!doc.uploadedFile" -->
                    <div v-if="files[lic.key] == null">
                      <label
                        :for="`doc-${i}-file`"
                        class="bg-gray-200 text-gray-500 text-md font-medium px-4 py-2 rounded leading-none cursor-pointer"
                        >Upload</label
                      >
                      <span
                        class="ml-2 text-brand-body border-b border-dashed border-brand-body text-sm font-light pb-0.5"
                      >
                        Sample</span
                      >
                    </div>
                    <!-- <span  v-for="(files, index) in file" :key="index"
                      class="text-brand-body border-b border-dashed border-brand-body text-sm font-light pb-0.5 text-center"
                      >{{ files.name }}</span>      -->
                    <div class="inline-flex" v-else>
                      <span
                        @click="showFile(lic.key)"
                        class="flex items-center justify-center cursor-pointer"
                      >
                        <Icon name="document-file" class="w-4 h-4" />
                      </span>
                      <span
                        @click="removeFile(lic.key)"
                        class="flex items-center justify-center cursor-pointer"
                        ><Icon name="delete" class="w-5 h-5"
                      /></span>
                    </div>
                    <!-- <div class="w-20">
                    <span
                      v-if="!doc.uploadedFile"
                      class="text-brand-body border-b border-dashed border-brand-body text-sm font-light pb-0.5"
                      >Sample</span
                    >
                    <span
                      v-else
                      class="flex items-center justify-center cursor-pointer"
                      ><Icon name="delete" class="w-5 h-5"
                    /></span>
                    </div> -->
                  </div>
                </div>
                <p
                  class="flex text-left text-sm font-light text-red-400 pt-2"
                  v-if="
                    submit && lic.required == true && files[lic.key] == null
                  "
                >
                  This License field is required.
                </p>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-5 pt-5">
              <div @click="willdoLater">
                <Button title="I'll do later" class="no-bg" />
              </div>
              <div @click="submitFile">
                <Button title="Continue" />
              </div>
            </div>
          </div>
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep > 2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-medium">
              Successfully Submitted
            </p>
            <span class="text-md text-brand-body"
              >we are currently reviewing your documents.</span
            >
          </div>
          <!-- if no files are added then show this -->
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep === 1.2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-medium">
              Successfully Submitted
            </p>
            <span class="text-md text-brand-body"
              >Your new address is ready to use.</span
            >
          </div>
        </div>
      </Block>
      <Confirm
        :isVisible="showConfirmDialouge"
        cancelButton="Go Back"
        confirmButton="Yes"
        @cancel="showConfirmDialouge = false"
        @confirm="Yes"
      >
        <p>
          The address you entered does not appear to be a commercial address.
        </p>
        <p>
          Are you sure this is the correct address?
          <br />{{ address.address1 }}<br />
          {{ address.city }}, {{ address.state.state }}
          {{ address.zip_code.zip_code }}
        </p>
      </Confirm>
      <!-- File Preview Modal  -->
      <ModalFull
        :is-visible="previewFileModal"
        @close="previewFileModal = false"
      >
        <Block title="License File" :streachActions="true">
          <template #action>
            <div
              class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
            >
              <div class="steps flex items-stretch space-x-4"></div>
              <button
                class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
                @click="previewFileModal = false && currentStep === 1"
              >
                <XIcon class="w-5 h-5" />
              </button>
            </div>
          </template>
          <div>
            <div
              class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            >
              <embed
                :src="filepreview[selectedLicense]"
                width="100%"
                style="max-height: 30rem; min-height: 10rem"
              />
            </div>
          </div>
        </Block>
      </ModalFull>
      <!-- File Preview Modal Ends -->
    </ModalFull>

    <!-- Edit Address Modal -->
    <ModalFull
      :is-visible="updateAddressModal"
      @close="updateAddressModal = updatesubmit = false"
    >
      <Block icon="shop" title="Location Details" :streachActions="true">
        <template #action>
          <div
            class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
          >
            <div class="steps flex items-stretch space-x-4"></div>
            <button
              class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
              @click="updateAddressModal = updatesubmit = false"
            >
              <XIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
        <div>
          <div class="flex flex-col space-y-8">
            <div>
              <!-- <div class="flex items-center justify-end mb-4">
                <span
                  class="flex items-center uppercase font-normal text-xs cursor-pointer text-brand-body"
                  @click="showDeleteDialouge = true"
                  >Delete<Icon name="delete" class="ml-1 -mt-1 w-4 h-4"
                /></span>
              </div> -->

              <div class="grid grid-cols-2 gap-4 ">
                <div class="col-span-2 input">
                  <Input
                    v-model:modelValue="nickname_edit"
                    placeholder="Location Name"
                    maxlength="32"
                    @keyup="checkEditValue()"
                  >
                    <div class="flex items-center w-full space-x-2">
                      <label for="">Location Name</label>
                      <tippy interactive>
                        <span class="cursor-pointer">
                          <Icon
                            name="info"
                            class="w-4 h-4 -mt-0.5 text-theme-secondary opacity-70"
                          />
                        </span>
                        <template #content>
                          <p class="text-left px-1.5 text-xs">
                            A location name will change the name of your
                            location on the shipping label. This is helpful when
                            you have a company name but stores with different
                            names.
                            <br />
                            By default, if you don't enter a location name,
                            <b>{{ company_name }}</b> will be the location on
                            the shipping label.
                          </p>
                        </template>
                      </tippy>
                    </div>
                  </Input>
                </div>
                <div class="col-span-2 input">
                  <Input
                    label="Address Line 1"
                    :modelValue="
                      editadd.data.address
                        ? editadd.data.address.address1
                        : null
                    "
                    placeholder="Address Line "
                    disabled
                  />
                </div>
                <div
                  class="col-span-2 input"
                  v-if="
                    editadd.data.address
                      ? editadd.data.address.address2
                        ? true
                        : false
                      : false
                  "
                >
                  <Input
                    label="Address Line 2"
                    :modelValue="
                      editadd.data.address
                        ? editadd.data.address.address2
                        : null
                    "
                    placeholder="Address Line 2"
                    disabled
                  />
                </div>

                <div class="col-span-2 grid grid-cols-3 gap-8">
                  <div class="input">
                    <Input
                      label="City"
                      placeholder="City"
                      :modelValue="
                        editadd.data.address ? editadd.data.address.city : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.city" -->
                  </div>

                  <div class="input">
                    <Input
                      label="State"
                      placeholder="State"
                      :modelValue="
                        editadd.data.address ? editadd.data.address.state : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.state" -->
                  </div>

                  <div class="input">
                    <Input
                      label="Zip Code"
                      placeholder="Zipcode"
                      :modelValue="
                        editadd.data.address
                          ? editadd.data.address.zip_code
                          : null
                      "
                      disabled
                    />
                    <!-- v-model:modelValue="address.zip_code" -->
                  </div>
                </div>
                <!-- <div class="col-span-2 flex justify-end">
                  <div class="w-full flex-shrink-0" @click="saveNickname()">
                    <Button title="Update Address" />
                  </div>
                </div> -->
              </div>
              <!-- <p
                class="mt-1.5 w-full text-left text-md font-light text-brand-body"
              >
                {{
                  editadd.data.address ? editadd.data.address.address1 : null
                }},<br />
                {{ editadd.data.address ? editadd.data.address.city : null }}
                {{ editadd.data.address ? editadd.data.address.state : null }}
                {{
                  editadd.data.address ? editadd.data.address.zip_code : null
                }}
              </p> -->
            </div>

            <div
              class="flex items-center justify-between"
              v-show="editadd.data.length > 0"
            >
              <h3 class="text-base font-medium text-theme-secondary">
                Your documents
              </h3>
            </div>
            <div class="flex flex-col space-y-7">
              <div v-for="(lic, i) in editadd.data.license" :key="lic.key">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <span
                      v-if="
                        lic
                          ? lic.document
                            ? lic.document.is_approved == 0
                            : null
                          : null
                      "
                      ><Icon name="document-warning" class="flex w-5 h-5 mr-3.5"
                    /></span>
                    <span
                      v-else-if="filedocument[lic.key] != null || lic.document"
                      class="w-5 h-5 border border-green-400 flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0 mr-3.5"
                      ><CheckIcon class="w-3.5 h-3.5"
                    /></span>
                    <span
                      v-else
                      class="w-5 h-5 border bordergray-100 rounded-full flex-shrink-0 mr-3.5"
                    ></span>
                    <p class="text-md text-theme-secondary">{{ lic.name }}</p>
                  </div>
                  <div class="flex items-center justify-end space-x-5">
                    <input
                      @click="handle($event)"
                      @change="FileUpload($event, lic.key)"
                      type="file"
                      :id="`doc-${i}-file`"
                      class="absolute w-0 h-0 opacity-0"
                    />
                    <div
                      v-if="lic.document || filedocument[lic.key] != null"
                      class="inline-flex"
                    >
                      <span
                        @click="
                          showEditFile(
                            lic.key,
                            lic.document ? lic.document.file_url : null
                          )
                        "
                        class="flex items-center cursor-pointer"
                      >
                        <Icon name="document-file" class="w-4 h-4" />
                      </span>
                      <span
                        @click="removeFiledocument(lic.key, i)"
                        class="flex items-center cursor-pointer"
                        ><Icon name="delete" class="w-5 h-5"
                      /></span>
                    </div>
                    <div v-else>
                      <label
                        :for="`doc-${i}-file`"
                        class="bg-gray-200 text-gray-500 text-md font-medium px-4 py-2 rounded leading-none cursor-pointer"
                        >Upload</label
                      >
                      <span
                        class="ml-2 text-brand-body border-b border-dashed border-brand-body text-sm font-light pb-0.5"
                      >
                        Sample</span
                      >
                    </div>
                  </div>
                </div>
                <p
                  class="ml-8 text-left text-md font-light text-brand-body"
                  v-if="
                    lic
                      ? lic.document
                        ? lic.document.is_approved == 0
                        : null
                      : null
                  "
                >
                  Reviewing or Invalid
                </p>
                <p
                  class="ml-8 text-left text-md font-light text-brand-body"
                  v-if="
                    lic.document ? lic.document.expiration_date != null : null
                  "
                >
                  Expires
                  {{ lic.document ? lic.document.expiration_date : null }}
                </p>
                <p
                  class="ml-8 flex text-left text-sm font-light text-red-400 pt-2"
                  v-if="
                    updatesubmit &&
                      lic.required == true &&
                      filedocument[lic.key] == null
                  "
                >
                  This License field is required.
                </p>
              </div>
            </div>
            <!-- editadd.data.address ? true : false -->
            <div class="col-span-2 flex justify-end">
              <div class="w-full flex-shrink-0" @click="updateFile">
                <Button title="Update" />
              </div>
            </div>
          </div>
          <div
            class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            v-show="currentStep === 2"
          >
            <div
              class="flex items-center justify-center w-20 h-20 bg-green-300 text-white rounded-full"
            >
              <CheckIcon class="w-16 h-16" />
            </div>
            <p class="text-base text-black font-medium">
              Successfully Deleted
            </p>
            <span class="text-md text-brand-body"
              >Your address is successfully deleted.</span
            >
          </div>
        </div>
      </Block>

      <!-- <Confirm
        :isVisible="showDeleteDialouge"
        cancelButton="Go Back"
        confirmButton="Yes"
        @cancel="showDeleteDialouge = false"
        @confirm="delAddr"
      >
        <p>
          Are you sure you want to delete this address?
        </p>
      </Confirm> -->
      <ModalFull
        :is-visible="previewEditFileModal"
        @close="previewEditFileModal = false"
      >
        <Block title="License File" :streachActions="true">
          <template #action>
            <div
              class="flex-grow flex items-streach justify-between h-12 -my-4 pl-5"
            >
              <div class="steps flex items-stretch space-x-4"></div>
              <button
                class="text-sm font-medium text-gray-300 hober:text-gray-500 inline-flex items-center uppercase tracking-wider"
                @click="previewEditFileModal = false"
              >
                <XIcon class="w-5 h-5" />
              </button>
            </div>
          </template>
          <div>
            <div
              v-if="selectedUrl"
              class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            >
              <!-- :src="filepreview[selectedLicense]" -->
              <embed
                :src="filearr"
                width="100%"
                style="max-height: 30rem; min-height: 10rem"
              />
            </div>
            <div
              v-else
              class="flex items-center flex-col space-y-5 justify-center h-80 text-center px-8 py-5"
            >
              <embed
                :src="filepreview[selectedLicense]"
                width="100%"
                style="max-height: 30rem; min-height: 10rem"
              />
            </div>
          </div>
        </Block>
      </ModalFull>
    </ModalFull>
    <Confirm
      :isVisible="showDeleteDialouge"
      cancelButton="Go Back"
      confirmButton="Yes"
      :isConfirm="addresses.data.length > 1 ? true : false"
      @cancel="showDeleteDialouge = false"
      @confirm="delAddr"
    >
      <p v-text="addresses.data.length > 1 ? 'Are you sure you want to delete this address?' : 'Please add a new shipping address before deleting this address. No accounts can have 0 shipping addresses'"></p>
    </Confirm>
  </div>
</template>

<script>
import {
  DotsVerticalIcon,
  XIcon,
  ChevronLeftIcon,
  CheckIcon,
} from "@heroicons/vue/solid";
import { SelectorIcon } from "@heroicons/vue/solid";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxLabel,
  Combobox,
  ComboboxInput,
  ComboboxLabel,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue";
import { ref, inject } from "vue";
import Block from "../../common/Block.vue";
import Account from "@/modules/Account";
import { mapGetters } from "vuex";
import { required, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  queryAutocompleteForSuggestions,
  selectSuggestion,
  formatSuggestion,
} from "@/modules/AutocompleteAddress";
// const stores = [
//   {
//     shipping: 'Shipping'
//   },
//   {
//     shipping: 'Billing'
//   },
//   {
//     shipping: 'Main'
//   },
// ];

export default {
  name: "Stores",
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ListboxLabel,
    Combobox,
    ComboboxInput,
    ComboboxLabel,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    SelectorIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Block,
    XIcon,
    ChevronLeftIcon,
    CheckIcon,
  },
  data() {
    return {
      v$: useVuelidate(),
      isSubmit: false,
      submit: false,
      // updatesubmit: false,
      showConfirmDialouge: false,
      showDeleteDialouge: false,
      totalVuePackages: null,
      files: [],
      filedocument: [],
      selectedUrl: "",
      filearr: "",
      state_code: "",
      tradegeckoId: null,
      filepreview: [],
      selectedLicense: "",
      isDisabled: true,
      nickname_edit: "",
      company_name: localStorage.getItem("company_name"),
    };
  },
  validations() {
    return {
      address: {
        address1: { required },
        city: { required },
        state: { required },
        // label: {required},
        zip_code: { required, minLength: minLength(4) },
      },
    };
  },
  methods: {
    queryAutocompleteForSuggestions,
    selectSuggestion,
    formatSuggestion,
    willdoLater() {
      this.currentStep = 1.2;
      this.reset();
      // this.showAddressModal = false;
      this.getAddress();
    },

    checkValue() {
      this.address["nickname"] = this.address["nickname"].replace(
        /[^a-zA-Z0-9 ]/g,
        ""
      );
    },
    checkEditValue() {
      this.nickname_edit = this.nickname_edit.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    saveNickname() {
      console.log(this.editadd.data.address);
    },
    delAddr() {
      console.log(this.tradegeckoId);
      this.deleteAddress(this.tradegeckoId);
      this.showDeleteDialouge = false;
      // this.updateAddressModal = false;
      this.currentStep = 2;
      // this.currentStep = 2;
    },

    delAddress(trade_gecko_id) {
      this.tradegeckoId = trade_gecko_id;
      // this.deleteAddress(this.tradegeckoId);
      this.showDeleteDialouge = true;
      // this.updateAddressModal = false;
      // this.currentStep = 2;
      // this.currentStep = 2;
    },
    reset() {
      this.address = {
        address1: "",
        city: "",
        nickname: "",
        state: "",
        label: "shipping",
        // zip_code:""
      };
      this.files = null;
    },
    show() {
      this.showAddressModal = true;
      if (
        this.currentStep == 2 ||
        this.currentStep == 3 ||
        this.currentStep == 1.2
      ) {
        this.address = {
          address1: "",
          city: "",
          nickname: "",
          state: "",
          label: "",
        };
        this.currentStep = 1;
      }
    },
    handle(e) {
      e.target.value = "";
    },
    handleFileUpload(e, key) {
      this.files[key] = e.target.files[0];
      this.filepreview[key] = URL.createObjectURL(e.target.files[0]);
    },
    FileUpload(e, key) {
      this.filedocument[key] = e.target.files[0];
      this.filepreview[key] = URL.createObjectURL(e.target.files[0]);
    },
    removeFiledocument(key, k) {
      this.filedocument[key] = null;
      this.editadd.data.license[k].document = "";
    },
    removeFile(key) {
      this.files[key] = null;
    },
    showFile(key) {
      this.previewFileModal = true;
      this.selectedLicense = key;
    },
    showEditFile(key, url) {
      this.previewEditFileModal = true;
      this.selectedUrl = url;
      if (this.selectedUrl) {
        this.filearr =
          "https://admin.medusadistribution.com/license/" + this.selectedUrl;
        return this.filearr;
      } else {
        this.selectedLicense = key;
      }
    },
    updateFile() {
      this.updatesubmit = true;
      const f = [this.filedocument];
      let formData = new FormData();
      for (const key in f[0]) {
        formData.append(`license[${key}]`, f[0][key]);
      }
      formData.append("address_id", this.editadd.addId);
      formData.append("nickname", this.nickname_edit);
      this.axios.authApi
        .post("/account/addLicense", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        // .then(res => console.log("response",res));
        .then((resp) => {
          if (resp.data.status) {
            //  console.log("response", resp);
            this.updateAddressModal = false;
            this.getAddress();
            this.updatesubmit = false;
          } else {
            // address.error = resp.data.error;
            console.log("response", resp);
          }
        });
    },
    submitFile() {
      this.submit = true;
      const f = [this.files];
      let formData = new FormData();
      for (const key in f[0]) {
        formData.append(`license[${key}]`, f[0][key]);
      }
      formData.append("address_id", this.address.address_id);
      this.axios.authApi
        .post("/account/addLicense", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        // .then(res => console.log("response",res));
        .then((resp) => {
          if (resp.data.status) {
            //  console.log("response", resp);
            this.currentStep = 3;
            this.reset();
            this.getAddress();
          } else {
            // address.error = resp.data.error;
            console.log("response", resp);
          }
        });
    },
    edit(ids, nickname) {
      this.filedocument = [];
      this.tradegeckoId = ids;
      this.editAddress(ids);
      this.updateAddressModal = true;
      this.currentStep = 1;
      this.nickname_edit = nickname;
    },

    selectZipcode(state) {
      this.state_code = state;
      this.getZipcodes(state);
      this.address.zip_code = "";
    },
    Yes() {
      // if(this.address.label.shipping == 'Billing'){
      //   this.currentStep = 3;
      // }
      // else{
      this.currentStep = 2;
      // }
      this.addAddress("Shipping", this.address);
      this.isDisabled = false;
      this.showConfirmDialouge = false;
    },
    save() {
      this.isSubmit = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        fetch(
          "https://us-street.api.smartystreets.com/street-address?street=" +
            this.address.address1 +
            "&city=" +
            this.address.city +
            "&state=" +
            this.address.state.state +
            "&zipCode=" +
            this.address.zip_code.zip_code +
            "&key=115379242330163169&candidates=10&match=invalid"
        )
          .then((response) => response.json())
          .then((data) => {
            this.totalVuePackages = data[0].metadata.rdi;
            if (this.totalVuePackages != "Commercial") {
              this.showConfirmDialouge = true;
            }
            // else if(this.address.label.shipping == 'Billing'){
            //   this.addAddress("Shipping");
            //   this.currentStep = 3;
            // }
            else {
              this.addAddress("Shipping", this.address);
              this.isDisabled = false;
              this.currentStep = 2;
            }
          });
      } else {
      }
    },
  },
  setup() {
    // let query = ref('')
    // let filteredPeople = computed(() =>
    //   query.value === ''
    //     ? people
    //     : people.filter((person) =>
    //         person.name
    //           .toLowerCase()
    //           .replace(/\s+/g, '')
    //           .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    //       )
    // )
    const query = ref("");
    const axios = inject("axios");
    const {
      address,
      editadd,
      getAddress,
      addresses,
      addAddress,
      st,
      getStates,
      zipcode,
      getZipcodes,
      addLicense,
      Licenses,
      addressId,
      editAddress,
      deleteAddress,
    } = Account();
    getAddress();
    getStates();
    const showAddressModal = ref(false);
    const updateAddressModal = ref(false);
    const previewFileModal = ref(false);
    const previewEditFileModal = ref(false);
    const updatesubmit = ref(false);
    const currentStep = ref(1);
    return {
      updatesubmit,
      query,
      editadd,
      addresses,
      getAddress,
      deleteAddress,
      editAddress,
      Licenses,
      addressId,
      addLicense,
      st,
      getStates,
      zipcode,
      getZipcodes,
      address,
      addAddress,
      // stores,
      showAddressModal,
      updateAddressModal,
      previewEditFileModal,
      previewFileModal,
      currentStep,
      axios,
    };
  },
};
</script>

<style lang="scss" scoped>
.options-btn {
  @apply text-theme-body-light opacity-60 hover:opacity-100 focus:outline-none focus:ring-transparent;
}

.options-list {
  @apply flex flex-col origin-top-right absolute -mt-4 top-full right-4 w-32 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-10 divide-y divide-gray-100;

  .options-item {
    @apply block text-center text-xs text-theme-dark text-opacity-70 font-normal leading-4 px-3 py-2.5 hover:bg-gray-50;
  }
}
.card-btn {
  @apply text-sm font-medium text-theme-body hover:text-theme-secondary inline-flex items-center uppercase tracking-wide leading-none;
  svg {
    @apply w-4 h-4;
  }
}
.wd-50 {
  width: 50rem;
}

.modal {
  ::v-deep .input {
    input {
      height: 3.2rem;
    }
  }
  input {
    margin-top: 0.1rem;
    border: 2px solid #e9e9e9;
    border-radius: 0.25rem;
    height: 3.2rem;
  }
  .ss-btn {
    border: 2px solid #e9e9e9;
    border-radius: 0.25rem;
    @apply px-2 #{!important};
  }
}
.store-switcher {
  @apply relative w-full inline-block text-left mt-0;

  .ss-btn {
    @apply w-full px-6 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent;
  }

  .item-opts {
    @apply z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none;

    .item-opt {
      @apply w-full px-4 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent cursor-pointer;
    }

    .name {
      @apply text-brand-secondary text-sm font-medium truncate;
    }

    .place {
      @apply text-brand-body text-xs truncate;
    }
  }
}
</style>
