<template>
  <tr class="row-loader" v-for="x in rows" :key="x">
    <td><span>XXXX</span></td>
    <td class="hidden lg:table-cell"><span>XXX xxx XX</span></td>
    <td class="hidden 2xl:table-cell">
      <span>xxxx</span>
    </td>
    <td class="hidden lg:table-cell"><span>XXXX</span></td>
    <td class="hidden 2xl:table-cell"><span>XXXX</span></td>
    <td class="hidden lg:table-cell"><span>XXXX</span></td>
    <td><span>XXXX XX</span></td>
  </tr>
</template>

<script>
export default {
  name: "OrdersRowLoader",
  props: {
    rows: {
      type: Number,
      default: 8,
    },
  },
};
</script>
<style lang="scss" scoped>
.row-loader {
  @apply bg-white;
  td {
    @apply px-6 py-4 whitespace-nowrap;
    span {
      @apply inline-block text-transparent bg-gray-100 animate-pulse rounded-lg;
    }
  }
}
</style>
