<template>
  <ul class="pagination" :x="totalPages">
    <li
      v-if="currentPage != 1"
      class="prev arrows"
      @click="$emit('paginate', currentPage - 1 > 0 ? currentPage - 1 : null)"
    >
      <ArrowNarrowLeftIcon /><span class="hidden sm:inline-block">Prev</span>
    </li>
    <li
      v-if="currentPage > 2"
      :class="currentPage === 1 ? 'current' : ''"
      @click="$emit('paginate', 1)"
    >
      1
    </li>
    <li v-if="currentPage > 2" class="pointer-events-none">...</li>
    <template
      v-for="num in totalPages > 1 ? totalPages - 1 : totalPages"
      :key="num"
    >
      <li
        v-if="num <= currentPage + 1 && num >= currentPage - 1"
        :class="currentPage === num ? 'current' : ''"
        @click="$emit('paginate', num)"
      >
        {{ num }}
      </li>
    </template>
    <li v-if="currentPage < totalPages - 2" class="pointer-events-none">...</li>
    <li
      v-if="totalPages != 1"
      :class="currentPage === totalPages ? 'current' : ''"
      @click="$emit('paginate', totalPages)"
    >
      {{ totalPages }}
    </li>

    <li
      v-if="totalPages != currentPage"
      class="next arrows"
      @click="
        $emit(
          'paginate',
          currentPage + 1 <= totalPages ? currentPage + 1 : null
        )
      "
    >
      <span class="hidden sm:inline-block">Next</span><ArrowNarrowRightIcon />
    </li>
  </ul>
</template>

<script>
import { computed } from "vue";
import {
  ArrowNarrowRightIcon,
  ArrowNarrowLeftIcon,
} from "@heroicons/vue/outline";
export default {
  name: "Pagination",
  props: {
    total: {
      type: Number,
      defaul: 5,
    },
    active: {
      type: Number,
      defaul: 1,
    },
  },
  components: {
    ArrowNarrowRightIcon,
    ArrowNarrowLeftIcon,
  },
  setup(props) {
    const totalPages = computed(() => props.total);
    const currentPage = computed(() => props.active);

    return {
      totalPages,
      currentPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  @apply inline-flex bg-white px-3 py-4 select-none;
  li {
    @apply text-sm uppercase font-medium text-gray-600 px-3 py-2 rounded cursor-pointer hover:bg-gray-100 leading-none;

    &.current {
      @apply bg-gray-100;
    }
  }

  .arrows {
    @apply flex items-center space-x-2;

    svg {
      @apply w-4 h-4;
    }
  }
}
</style>
