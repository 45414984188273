<template>
  <div class="support-card">
    <div class="title-block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        fill="none"
        viewBox="0 0 28 28"
      >
        <path
          fill="#fff"
          fill-rule="evenodd"
          d="M2.333 6.5a3 3 0 013-3h17.333a3 3 0 013 3v11.011l.03-.011v5.753c0 .89-1.078 1.337-1.708.707L21.028 21H5.334a3 3 0 01-3-3V6.5zm5.186 4.196a1.167 1.167 0 00-.323 1.618c1.76 2.642 4.057 4.02 6.804 4.02 2.746 0 5.043-1.378 6.804-4.02a1.167 1.167 0 00-1.942-1.294C17.512 13.044 15.92 14 14 14s-3.513-.956-4.863-2.98a1.167 1.167 0 00-1.618-.324z"
          clip-rule="evenodd"
        />
      </svg>
      <h3>Your Representative</h3>
    </div>
    <div class="divider"></div>
    <div class="rep-info">
      <img
        class="rep-avatar"
        :src="
          imageBaseUrl(coins.representative ? coins.representative.path : null)
        "
      />
      <div
        class="flex flex-col leading-none py-3"
        @click="onRepContact($event)"
      >
        <p class="text-base text-white mb-0.5 leading-none">
          {{
            coins.representative
              ? coins.representative.name + " " + coins.representative.last_name
              : null
          }}
        </p>
        <a href="#">{{
          coins.representative ? coins.representative.email : null
        }}</a>
      </div>
    </div>
    <div class="flex items-center space-x-4 pb-2">
      <div class="flex items-center justify-center w-10 h-5">
        <Icon name="phone" class="w-4 h-4 text-white" />
      </div>
      <a
        :href="
          'tel:' +
            (coins.representative ? coins.representative.phone_number : null)
        "
        >{{
          formattedNumber(
            coins.representative ? coins.representative.phone_number : null
          )
        }}
      </a>
    </div>
    <div class="flex items-center space-x-4 pb-2">
      <div class="flex items-center justify-center w-10 h-5">
        <Icon name="message" class="w-4 h-4 text-white" />
      </div>
      <a href="#" @click="onRepContact($event)" class="underline"
        >Contact your rep now</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SupportCard",
  computed: {
    ...mapGetters(["coins"]),
  },
  setup() {
    const imageBaseUrl = (img) => {
      if (img === null) {
        return "/images/no-image.png";
      }
      const base_url_img = process.env.BASE_URL_IMG || "/";
      return base_url_img + "/" + img;
    };

    const formattedNumber = (phoneNUmber) => {
      //Filter only numbers from the input
      let cleaned = ("" + phoneNUmber).replace(/\D/g, "");

      //Check if the input is of correct
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }

      return null;
    };

    const onRepContact = (e) => {
      e.preventDefault();
      document.querySelector("#rep-panel button")?.click();
    };

    return {
      imageBaseUrl,
      formattedNumber,
      onRepContact,
    };
  },
};
</script>

<style lang="scss" scoped>
.support-card {
  @apply flex flex-col items-stretch justify-center space-y-1.5 px-6 pt-4 pb-3.5 h-full;
  background: #474557;

  .title-block {
    @apply flex items-center space-x-5;

    svg {
      @apply w-8 h-8;
    }
  }

  .divider {
    @apply block border-b-2 border-white border-opacity-10 -mx-6 pt-1;
  }

  .rep-info {
    @apply flex items-center space-x-4 pt-2;

    .rep-avatar {
      @apply w-10 h-10 rounded-full flex-shrink-0 object-cover object-center;
      aspect-ratio: 1/1;
    }
  }

  svg {
    @apply h-8;
  }

  h3 {
    @apply text-lg text-white font-medium;
  }

  a {
    @apply text-white text-opacity-75 text-sm;
  }
}
</style>
